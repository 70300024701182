@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
  font-weight: 300;
}

body {
	//padding-top: 24px;
	font-size: 150%;
}

.alert h1 {
	text-align: center;
	font-size: 175%;
	margin-top: 0px;
}

.panel > .panel-heading {
	font-size: 200%;
	font-weight: bolder;
}

.hidden {
	display: none;
}
.modal-backdrop.in { z-index: auto;}
.working-spinner {
  text-align: center;
}
